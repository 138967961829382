<template>
  <div class="page-body">
    <div class="page-section w-96 mx-auto">
      <h3 class="text-sm my-8 text-center uppercase">Select a contact to send your list</h3>
      <div>
        <div class="flex">
          <div class="flex-grow">
            <input type="email" v-model="newContact" placeholder="Type new email address..." class="w-full h-12 px-2 border-b focus:placeholder-pink-600 outline-none placeholder-pink-500">
          </div>
          <div class="flex-none w-20">
            <a :href="generateEmail()" @click="onShare(undefined, $event)" class="btn-blue inline-block h-12 leading-8 active:translate-y-1">
              Send
            </a>
          </div>
        </div>
        <div class="flex my-4" v-for="contact in contacts" :key="contact">
          <div class="flex-grow">
            <div class="h-12 leading-10 border-b px-2">{{contact}}</div>
          </div>
          <div class="flex-none w-20">
            <a :href="generateEmail(contact)" @click="onShare(contact, $event)" class="btn-blue inline-block h-12 leading-8 active:translate-y-1">
              send
            </a>
          </div>
        </div>
      </div>
      <div class="mt-16 text-center">
        <button class="btn-white active:translate-y-1">Edit contacts</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ListShare',
  components: {
  },
  data: () => {
    return {
      newContact: '',
      subject: encodeURI('You received a Flylist!'),
      body: encodeURI(`You can access your Flylist via the link below.

https://flylist.app/share/`)
    }
  },
  computed: {
    ...mapState(['contacts', 'listInfo']),
  },
  methods: {
    onShare (contact, event) {
      if (contact === undefined && this.isValidEmail(this.newContact)) {
        this.share(this.newContact)
        this.createContact()
      } else if (contact) {
        this.share(contact)
      } else {
        event.preventDefault()
      }
    },
    share (contact) {
      this.$store.dispatch('shareList', contact)
    },
    createContact() {
      this.$store.dispatch('createContact', this.newContact)
      this.newContact = ''
    },
    generateEmail(contact) {
      const to = contact ? contact : this.newContact
      return `mailto:${to}?subject=${this.subject}&body=${this.body}${this.listInfo.id}`
    },
    isValidEmail (email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }
  },
  mounted () {
    this.$store.dispatch('fetchContacts')
    this.$store.dispatch('fetchList')
    this.$store.commit('setPageTitle', 'Share Your List')
  }
}
</script>
